import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getReportTypes, getExcelReport } from '../../services/reportService';
import { formatedTimestamp } from '../../utils/format-date'
import Spinner from '../common/Spinner';

const customStyles = {
    content: {
        top: '70px', // Adjust this value based on the header's height
        right: '20px', // Adjust for right alignment
        left: 'auto', // Auto to override default styles
        bottom: 'auto', // Auto to override default styles
        height: '500px',
        width: '400px',
        overflow: 'hidden',
    },
    overlay: {
        background: 'transparent'
    },
};

const ReportsModal = ({ isOpen, onRequestClose }) => {

    const [reportTypes, setReportTypes] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            getReportTypes()
                .then(data => {
                    setReportTypes(data);
                    if (data.length > 0) {
                        setSelectedReportType(data[0].key);
                    }
                })
                .catch(err => console.error('Error fetching report types:', err));
        }
    }, [isOpen]);

    const handleReportTypeChange = (event) => {
        setSelectedReportType(event.target.value);
    };

    const handleDownloadReport = async () => {
        setIsLoading(true); // Set loading to true before starting the download
        try {
            const reportBlob = await getExcelReport(selectedReportType);
            if (reportBlob) {
                const url = window.URL.createObjectURL(new Blob([reportBlob]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', `report_${selectedReportType}_${formatedTimestamp()}.xlsx`);

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error('Error downloading report:', error);
            // Handle any errors here
        } finally {
            setIsLoading(false); // Set loading to false after download completes or fails
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="LPC Reports Modal"
            style={customStyles}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">LPC Reports</h2>
                    <button onClick={onRequestClose} className="close-button">Close</button>
                </div>

                <div>
                    <select className="reports-dropdown" value={selectedReportType} onChange={handleReportTypeChange}>
                        {reportTypes.map((type, index) => (
                            <option key={index} value={type.key}>{type.value}</option>
                        ))}
                    </select>
                </div>

                {/* Spinner */}
                {isLoading && <Spinner />} {/* Render the spinner when loading */}

                <div>
                    <button onClick={handleDownloadReport} className="download-report-button" disabled={isLoading}>
                        {isLoading ? 'Downloading...' : 'Download Report'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ReportsModal;
