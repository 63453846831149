import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const FEATURE_SERVICE_URI = process.env.REACT_APP_FEATURE_SERVICE_URI;
const REPORT_SERVICE_URI = process.env.REACT_APP_REPORT_SERVICE_URI;

export const callApi = async (method, endpoint, params, data, serviceType, responseType = 'json') => {
  let response = {};

  // Determine the base URL based on the service type
  let baseURL;
  switch (serviceType) {
    case "base":
      baseURL = API_BASE_URL;
      break;
    case "feature":
      baseURL = FEATURE_SERVICE_URI;
      break;
    case "report":
      baseURL = REPORT_SERVICE_URI;
      break;
    default:
      throw new Error("Invalid service type specified");
  }

  try {
    response.response = await axios({
      method: method || "GET",
      url: `${baseURL}${endpoint}`,
      params: params,
      data: data,
      responseType: responseType
    });

    return response;
  } catch (err) {
    console.error(err.message);
    response.error = err.message;
    return response;
  }
};
