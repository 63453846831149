import { callApi } from '../app/action';

export const getReportTypes = async () => {
    try {
        const response = await callApi('GET', '/Reference/reportTypes', null, null, 'report');
        if (response && response.response && response.response.data) {
            return response.response.data;
        }
        throw new Error('No data received');
    } catch (error) {
        console.error("Error fetching boroughs: ", error);
        throw error;
    }
};

export const getExcelReport = async (reportType) => {
    try {
        console.log(`Attempting to fetch Excel report for type: ${reportType}`);
        const response = await callApi('GET', `/ExcelReports?ReportType=${reportType}`, null, null, 'report', 'blob');

        if (response && response.response && response.response.data) {
            return response.response.data;
        } else {
            console.log('No Excel report data returned');
            return null;
        }
    } catch (error) {
        console.error('Error fetching Excel report:', error);
        return null;
    }
};
