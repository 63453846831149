import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/common/header';
// Lazy loading the components
const ListingPage = lazy(() => import('./components/listing/listingPage'));
const Details = lazy(() => import('./components/details/detailsPage'));
//const NotFound = lazy(() => import('./components/notFound')); // A component for 404 pages

const App = () => {
    return (
        <>
            <Header />
            <Suspense>
                <Routes>
                    <Route path='/' element={<ListingPage />} />
                    <Route path='/details' element={<Details />} />

                </Routes>
            </Suspense>
        </>
    );
}

export default App;
