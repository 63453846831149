export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    let yy = date.getFullYear();
    let mm = date.getMonth();
    let dd = date.getDate();
    return `${dd > 9 ? dd : '0' + dd} ${months[mm]} ${yy}`;
};

export const formatedTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, '0');
    const day = `${now.getDate()}`.padStart(2, '0');
    const hours = `${now.getHours()}`.padStart(2, '0');
    const minutes = `${now.getMinutes()}`.padStart(2, '0');
    const seconds = `${now.getSeconds()}`.padStart(2, '0');
    return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
};
