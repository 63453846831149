import React, { useState } from 'react';
import ReportsModal from '../reportsModal/reportsModal';

const Header = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);


    return (
        <div className='w-full bg-blue-primary flex items-center justify-between p-5 text-white'>
            <div className='cursor-pointer text-[32px] leading-[32px] font-semibold'>CoreDataStore</div>
            <div className='flex text-[20px] leading-[24px]'>
                {/* Attach the openModal function to this div */}
                <div className='cursor-pointer mx-[20px] hover:opacity-60' onClick={openModal}>
                    LPC Reports
                </div>
            </div>

            {/* Include the ReportsModal component and pass the state and handlers */}
            <ReportsModal isOpen={modalIsOpen} onRequestClose={closeModal} />
        </div>
    )
};

export default Header;
